import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
//   mode:'history',
  routes: [
    {
      path: '/',
      name: '防伪查询',
      component: () =>import ("@/views/search.vue")
    },
    
  ]
  
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
